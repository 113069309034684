/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useCallback, useMemo, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import classnames from 'classnames';
import { MainLayout } from '../../templates/MainLayout';
import { YellowHeader } from '../../components/main/YellowHeader';
import { t } from '../../i18n';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../constants/hooks';
import ContactsMap from '../../components/contacts/ContactsMap';
import ContactsCard from '../../components/contacts/ContactsCard';
import { CITIES } from '../../constants/contacts';
import { IHeaderQuery } from '../../queries/headers/types';
import { BuyButton } from '../../components/main/BuyButton';

type IProps = PageProps<IHeaderQuery, { locale: string }>;

const ContactsPage: FC<IProps> = ({ data, pageContext: { locale = 'ru' } }) => {
  const prefix = usePathPrefix();
  const [tab, setTab] = useState<typeof CITIES[keyof typeof CITIES]>(
    CITIES.MOSCOW
  );
  const setTabMoscow = useCallback(() => setTab(CITIES.MOSCOW), [setTab]);
  const setTabNovosibirsk = useCallback(
    () => setTab(CITIES.NOVOSIBIRSK),
    [setTab]
  );

  const header = useMemo(
    () => data.headers.edges.find((edge) => edge.node),
    [data.headers, locale]
  );

  return (
    <MainLayout locale={locale} title="contacts.title">
      <YellowHeader header={t('contacts.title')}>
        {header?.node?.html || ''}
      </YellowHeader>

      <div className={classnames('content', styles.wrap)}>
        <ContactsMap tab={tab} setTab={setTab} />

        <div className={styles.grid}>
          <ContactsCard
            icon={`${prefix}/contacts/moscow.svg`}
            title={t('contacts.moscow')}
            iconAlt="star"
            selected={tab === CITIES.MOSCOW}
            onClick={setTabMoscow}
          >
            <address>{header?.node.frontmatter.moscow_address}</address>
          </ContactsCard>

          <ContactsCard
            icon={`${prefix}/contacts/novosibirsk.svg`}
            title={t('contacts.novosibirsk')}
            iconAlt="snowflake"
            selected={tab === CITIES.NOVOSIBIRSK}
            onClick={setTabNovosibirsk}
          >
            <address>{header?.node.frontmatter.novosibirsk_address}</address>
          </ContactsCard>

          <ContactsCard
            icon={`${prefix}/contacts/phone.svg`}
            title={
              <a href={`tel://${header?.node.frontmatter.phone}`}>
                {header?.node.frontmatter.phone}
              </a>
            }
            iconAlt="phone"
          >
            <div className={styles.address}>{t('contacts.phone_moscow')}</div>
          </ContactsCard>

          <ContactsCard
            icon={`${prefix}/contacts/email.svg`}
            title={
              <a href={`mailto:${header?.node.frontmatter.email}`}>
                {header?.node.frontmatter.email || 'header.email'}
              </a>
            }
            iconAlt="email"
          >
            <span>{t('contacts.email')}</span>
          </ContactsCard>
        </div>
        <div className={styles.button}>
          <BuyButton>{t('contacts.button')}</BuyButton>
        </div>
      </div>
    </MainLayout>
  );
};

export const query = graphql`
  query {
    headers: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/contacts/header/header/" } }
    ) {
      ...HeaderFields
    }
  }
`;

export default ContactsPage;
