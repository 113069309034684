import React, { FC, useEffect, useRef } from 'react';
import { initMap } from '../../../utils/map';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../../constants/hooks';

const LOCATIONS = {
  MOSCOW: { lat: 55.77315675385866, lng: 37.63158195660432 },
  NOVOSIBIRSK: { lat: 55.0335368, lng: 82.9331748 },
};

interface IProps {
  location: keyof typeof LOCATIONS;
}

const Map: FC<IProps> = ({ location = 'MOSCOW' }) => {
  const ref = useRef<HTMLDivElement>(null);
  const map = useRef<any>(null);
  const prefix = usePathPrefix();

  useEffect(() => {
    if (!ref.current) return;

    map.current = initMap(
      ref.current,
      Object.values(LOCATIONS),
      `${prefix}/contacts/marker.svg`
    );
  }, [ref.current]);

  useEffect(() => {
    if (!map.current || !location || !LOCATIONS[location]) return;

    map.current.setCenter(LOCATIONS[location]);
  }, [location, map.current]);
  return (
    <>
      <div ref={ref} className={styles.map} />
    </>
  );
};

export { Map };
